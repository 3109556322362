import React from 'react'
import DataSources from '../containers/DataSources'


const ProjectTwo = (props) => {
    debugger
    return (
        <div>
            <DataSources component ={props.astronauts}/>
        </div>
    )
}

export default ProjectTwo